import { createRouter, createWebHistory } from 'vue-router'
import { authModule } from "@/stores/auth.js";
import * as jose from 'jose'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/login.vue'),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('token')) {
          let token = localStorage.getItem('token')
          let decoded = jose.decodeJwt(token)
          if (decoded.exp < Date.now() / 1000) {
            next({ path: '/login' })
          } else {
            next({ path: '/' })
          }
        } else {
          next()
        }
      }
    },
    {
      path: '/activate',
      name: 'Activate',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/activate.vue')
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/index.vue')
    },
    {
      path: '/client/',
      name: 'Client List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/client/index.vue')
    },
    {
      path: '/client/add-new',
      name: 'Create Client',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/client/add-new.vue')
    },
    {
      path: '/client/:id',
      name: 'Edit Client',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/client/id.vue')
    },
    {
      path: '/package',
      name: 'Package List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/index.vue')
    },
    {
      path: '/package/add-new',
      name: 'Add Package',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/add-new.vue')
    },
    {
      path: '/package/bulk-add-new',
      name: 'Bulk Add Packages',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/bulk-add-new.vue')
    },
    {
      path: '/package/:id',
      name: 'Edit Package',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/id.vue')
    },
    {
      path: '/transaction',
      name: 'Transaction List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/transaction/index.vue')
    },
    {
      path: '/account',
      name: 'My Account',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/account.vue')
    },
    {
      path: '/admin/settings',
      name: 'Settings',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/admin/settings.vue')
    },
    //
    // {
    //   path: '/error/500',
    //   name: 'Error',
    //   meta: { layout: 'authLayout', requiresAuth: false },
    //   component: () => import('../views/error/500.vue')
    // },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error',
      meta: { layout: 'errorLayout', requiresAuth: false },
      component: () => import('../views/error/404.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = authModule()

  let tips = document.querySelectorAll('.tooltip')
  let pops = document.querySelectorAll('.popover')
  if (tips.length > 0) {
    tips.forEach((tooltip) => {
      tooltip.remove()
    });
  }
  if (pops.length > 0) {
    pops.forEach((popover) => {
      popover.remove()
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({path: '/login'})
    } else if (authStore.userObj.permissions.some(p => p.split('_')[1] === 'HIDDEN' && to.name.toLowerCase().includes(p.split('_')[0].toLowerCase()))) {
      next({path: from.fullPath})
    } else {
      let token = localStorage.getItem('token')
      let decoded = jose.decodeJwt(token)

      if (decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('token')
        next({ path: '/login' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

router.afterEach(( to, from, next) => {
  if (!to.path.includes('package')) {
    localStorage.removeItem('pkg_search')
  }
  if (!to.path.includes('client')) {
    localStorage.removeItem('clt_search')
  }
})

export default router
