<script>
  import { authModule } from "@/stores/auth.js";
  import { mapStores } from 'pinia'

  export default {
    name: "mainNav",

    data() {
      return {
        intervalId: null,
        refreshInterval: 5 * 60 * 1000
      }
    },
    mounted() {
      this.intervalId = setInterval(() => {
        this.authStore.fetchProfile();
      }, this.refreshInterval);
    },
    beforeUnmount() {
      clearInterval(this.intervalId);
    },
    computed: {
      ...mapStores(authModule)
    },
    methods: {
      logout() {
        this.authStore.logOut()
      }
    }
  }
</script>

<template>
  <header class="navbar navbar-expand-lg navbar-light bg-light navbar-shadow">
    <div class="container px-0 px-xl-3">
      <a class="navbar-brand order-lg-1 me-0 pe-lg-3 me-lg-4" href="/">
        <img src="/farin-to-yaad.png" alt="Logo" width="80">
      </a>
      <div class="d-flex align-items-center order-lg-3">
        <ul class="navbar-nav d-sm-inline-block d-none ms-3">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.name : 'No User' }}</a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="/admin/settings">Settings</a>
              </li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:void(0);" @click="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
        <button class="navbar-toggler ms-2 me-n3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse1" aria-expanded="false">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <nav class="collapse navbar-collapse order-lg-2" id="navbarCollapse1">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" :class="$route.path === '/' ? 'active': ''" href="/">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-if="authStore.isLoggedIn && !authStore.userObj.permissions.includes('CLIENT_HIDDEN')" :class="$route.path.includes('client') ? 'active': ''" href="/client">Clients</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-if="authStore.isLoggedIn && !authStore.userObj.permissions.includes('PACKAGE_HIDDEN')" :class="$route.path.includes('package') ? 'active': ''" href="/package">Packages</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-if="authStore.isLoggedIn && !authStore.userObj.permissions.includes('TRANSACTION_HIDDEN')" :class="$route.path.includes('transaction') ? 'active': ''" href="/transaction">Transactions</a>
          </li>
          <li class="nav-item dropdown d-sm-inline-block d-sm-none">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.name : 'No User' }}</a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="/admin/settings">Settings</a>
              </li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="javascript:void(0);" @click="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<style scoped>

</style>